import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Formik } from 'formik';
import axios from 'axios'

const Kontakt = () => (
    <Layout pageInfo={{ pageName: "kontakt" }}>
        <SEO title="Kontakt" description="Skontaktuj się z nami, a przedstawimy Ci najlepszą ofertę." />
        <Container fluid className="info-bar p-4">
            <div>
                <b>SKONTAKTUJ SIĘ Z NAMI - PRZYGOTUJEMY WYCENĘ TWOJEGO DACHU</b>
            </div>
        </Container>
        <Container fluid className="p-0">
            <div id="map">
                <iframe title="Lokalizacja walditech" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2432.219897352952!2d16.83144231634556!3d52.43893124998557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470444737b82c983%3A0x959c28fedbbb51cc!2sLubowska%2032%2C%2060-454%20Pozna%C5%84!5e0!3m2!1spl!2spl!4v1585770609829!5m2!1spl!2spl " width="100%" height="400" frameBorder="0" style={{ border: "0" }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </div>
        </Container>
        <Container className="mt-5">
            <Row>
                <Col md={6} className="contact-info-box">
                    <Row>
                        <Col xs={12}>
                            <h3 className="detail-title">Informacje kontaktowe</h3>
                            <div className="mt-4 contact-detail">Lubowska 32</div>
                            <div className="contact-detail">60-433 Poznań</div>
                            <div className="contact-detail">woj. wielkopolskie</div>
                            <p className="hr-thin"></p>
                            <div className="mb-3"><a href="tel:603105922"><span className="contact-detail">Tel:</span><span className="contact-detail-black"> 603 105 922</span></a></div>
                            <div><a href="mailto:kontakt@walditech.pl"><span className="contact-detail">E-mail:</span><span className="contact-detail-black"> kontakt@walditech.pl</span></a></div>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <h3 className="detail-title">Pozostańmy w kontakcie</h3>
                    </Row>
                    <Row>
                        <Col md={12} className="pl-0">
                            <Formik
                                initialValues={{ name: '', title: '', email: '', message: '' }}
                                validate={values => {
                                    const errors = {};
                                    if (!values.email) {
                                        errors.email = 'Pole jest wymagane';
                                    } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                    ) {
                                        errors.email = 'Niepoprawny adres email';
                                    }
                                    if (!values.name) {
                                        errors.name = 'Pole jest wymagane';
                                    }
                                    if (!values.title) {
                                        errors.title = 'Pole jest wymagane';
                                    }
                                    if (!values.message) {
                                        errors.message = 'Pole jest wymagane';
                                    }
                                    return errors;
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    axios.post("http://walditech.pl/contactform.php", values)
                                        // axios.post("http://localhost:8000/contactform.php", values)
                                        .then((res) => {
                                            console.log("Respoinse: ", res);
                                            alert("WiadomośĆ została wysłana");
                                            setSubmitting(false);
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col lg={6} className="pl-0 input-cols-contact">
                                                    <div className="form-group">
                                                        <input
                                                            className="input-form"
                                                            placeholder="Imię *"
                                                            type="text"
                                                            name="name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                        />
                                                    </div>
                                                    <div className="text-danger">
                                                        {errors.name && touched.name && errors.name}
                                                    </div>
                                                </Col>
                                                <Col lg={6} className="pr-0 input-cols-contact">
                                                    <div className="form-group">
                                                        <input
                                                            className="input-form"
                                                            placeholder="E-mail *"
                                                            type="e-mail"
                                                            name="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                        />
                                                    </div>
                                                    <div className="text-danger">
                                                        {errors.email && touched.email && errors.email}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="form-group">
                                                <input
                                                    className="input-form"
                                                    placeholder="Temat *"
                                                    type="text"
                                                    name="title"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.title}
                                                />
                                            </div>
                                            <div className="text-danger">
                                                {errors.title && touched.title && errors.title}
                                            </div>

                                            <div className="form-group">
                                                <textarea
                                                    className="input-form mytextarea"
                                                    placeholder="Treść wiadomości *"
                                                    type="text"
                                                    as="textarea"
                                                    name="message"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.message}
                                                >
                                                </textarea>
                                            </div>

                                            <div className="text-danger">
                                                {errors.message && touched.message && errors.message}
                                            </div>

                                            <button type="submit" className="form-submit" disabled={isSubmitting}>
                                                Wyślij wiadomość
                                    </button>
                                        </form>
                                    )}
                            </Formik>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default Kontakt